import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()

  return (
    <section className="payments-content">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <motion.h4 {...fadeAnimation}>
              {t(
                "Każda tapeta wykonana jest na indywidualne zamówienie o określonym wymiarze, kolorze, i teksturze, dlatego płatność może być dokonana jedynie w formie przedpłaty na konto. Dla Twojej wygody i bezpieczeństwa udostępniamy płatności online obsługiwane przez PayU oraz przelew tradycyjny, dzięki czemu możesz być spokojny o realizację Twojego zamówienia."
              )}
            </motion.h4>
            <div className="row">
              <motion.div className="col-sm-7" {...fadeAnimation}>
                <h3>{t("Płatność online")}</h3>
                <p>
                  {t(
                    "System płatności internetowych PayU umożliwia dokonywanie szybkich, wygodnych i bezpłatnych transakcji bez wychodzenia z domu. Za pośrednictwem PayU można zrealizować szybkie przelewy internetowe, płatność kartą, płatność BLIK, Google Pay, Masterpass, Visa Checkout, Płacę później (Twisto, PayPo) oraz Raty."
                  )}
                </p>
              </motion.div>
              <motion.div
                className="col-md-4 offset-md-1 col-sm-5"
                {...fadeAnimation}
              >
                <div className="payments-content__logo">
                  <img src={require("assets/icons/payu.svg").default} alt="" />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
